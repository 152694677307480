import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import UploadDialog from './LearnerSPUploadDialog';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";
import EnhancedTable from '../../widgets/ResponsiveTable';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const headCells = [
    { id: 'DocumentType', numeric: false, disablePadding: true, label: 'Document Type' },
    { id: 'Document', numeric: false, disablePadding: true, label: 'Document', contentType: 'document' },
    { id: 'UploadedBy', numeric: false, disablePadding: true, label: 'Uploaded By' },
    { id: 'DateUploaded', numeric: false, disablePadding: true, label: 'Date Uploaded' }
];

const toolbarRef = React.createRef();
class LearnerSkillsProgrammeDocumentSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addDialogOpen: false,
            selectedDocument: null,
            propmtOpen: false,
            promptTitle: "Delete Document",
            promptContent: "Are you sure you want to delete this Learner Skills Programme document?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "LearnerSkillsProgrammeID", Value: props.location.state.LearnerSkillsProgrammeID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Upload Document', icon: 'UploadIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'remove', label: '', tooltip: 'Remove Document', disabled: utils.isNull(this.state.selectedDocument), icon: 'DeleteIcon', visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };
    }


    handleToolbarButtonClick(buttonId) {
        if (buttonId === "add") {
            this.setState({ addDialogOpen: true });

        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddLearnerSkillsProgram',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    SkillsProgrammeID: this.props.location.state.LearnerSkillsProgrammeID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.location.state.permissions }
            });
        } else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedDocument: null,
                message: response.Message, messageStyle: response.MessageType.toLowerCase()
            })
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    deletDocument(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })
        let fullUrl = `${url}?id=${this.state.selectedDocument}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    handleToolbarButtonMenuClick(menu) {
        this.setState({ linkDialogOpen: true, selectedLinkType: menu });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedDocument: selection[0].Id,
                message: "",
                messageStyle: ""
            },
                () => {
                    toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0)
                }
            )
        }
        else {
            this.setState({
                selectedDocument: null
            },
                () => {
                    toolbarRef.current.setButtonDisabled("remove", true)
                }
            )
        }
    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    selectedDocument: null,
                    message: response.Message,
                    messageStyle: "success"
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    selectedDocument: null,
                    message: response.Message,
                    messageStyle: "error"
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
            else if (response.MessageType === "WARNING") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    addDialogOpen: false,
                    selectedDocument: null,
                    message: response.Message,
                    messageStyle: "warning"
                });
                toolbarRef.current.setButtonDisabled("remove", true);
            }
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedDocument: null
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    FileDownload = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            console.log(headCell);
            utils.invokeUrl("api/sims/LearnerSkillsProgrammeDocument/downloaddocument?id=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"Learner Skills Programme > Documents"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false} ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)}
                        config={this.toolbarConfig} />
                    <EnhancedTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/LearnerSkillsProgrammeDocument/GetSearch"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        paged={true} />
                </div>

                <UploadDialog
                    open={this.state.addDialogOpen}
                    closeHandler={(cancel, save, response) => this.postAddHandler(cancel, save, response)}
                    viewRef={this}
                    LearnerSkillsProgrammeID={this.props.location.state.LearnerSkillsProgrammeID}
                />

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.deletDocument(`api/sims/LearnerSkillsProgrammeDocument/DeleteLearnerProgrammeDocument`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnerSkillsProgrammeDocumentSearch));