import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from '../../ViewUtils';
import { PDFDownloadLink, Document, Page, StyleSheet, Text, Image, View,Font } from '@react-pdf/renderer';
import mqalogo from '../../../content/images/logos/mqa_logo.png';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { format } from 'date-fns'
import Arial from '../../../fonts/ARIAL.TTF'
import ArialItalic from '../../../fonts/ARIALI 1.TTF'
import ArialBold from '../../../fonts/ARIALBD.TTF'
import ArialBoldItalic from '../../../fonts/ARIALBI 1.TTF'

const utils = new ViewUtils();

Font.register({
    family: 'Arial',
    fonts: [
      { src: Arial },
      { src: ArialItalic, fontStyle: 'italic' },
      { src: ArialBold, fontWeight: 'bold' },
      { src: ArialBoldItalic, fontStyle: 'italic',fontWeight: 'bold' }
    ]
  })

const styles = StyleSheet.create({
    header: {
        fontSize: 20,
        marginBottom: 10,
        textAlign: 'center',
        color: '#1C2674',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        backgroundColor: "#E36C0A",
        borderBottom: "solid",
        borderBottomColor: "#1C2674",
        borderBottomWidth: 2,
        borderTop: "solid",
        borderTopColor: "#1C2674",
        borderTopWidth: 2
        
    },
    text: {
        fontFamily: 'Arial',
        fontSize: 9,
        marginBottom: 10,
        textAlign: 'left',
        color: 'black',
    },
    body: {
        flexDirection: 'column',
        padding: 25
    },
    image: {
        marginBottom: 10,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100px',
        width: '200px',
        padding: 10
    },
    table: {
        display: "table",
        width: "auto",
        borderTop: "solid",
        borderTopColor: '#1C2674',
        borderTopWidth: 2
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        backgroundColor: '#E36C0A'
    },
    tableCol: {
        borderTop: "solid",
        borderTopColor: '#000',
        borderTopWidth: 0.5
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        fontSize: 8,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        color: 'black'
    },
    tableCell: {
        fontFamily: 'Arial',
        margin: "auto",
        margin: 5,
        fontSize: 8
    },
    tableCellBold: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
        margin: "auto",
        margin: 5,
        fontSize: 8
    },
    tableColBold: {
        borderTop: "solid",
        borderTopColor: '#000',
        borderTopWidth: 2
    },
    tableColBoldBlue: {
        borderTop: "solid",
        borderTopColor: '#1C2674',
        borderTopWidth: 2
    },
    hr: {
        borderTop: "solid",
        borderTopColor: "#1C2674",
        borderTopWidth: 2
      }
});


export default function GrantStatement(props) {


    const initiateHeaderValue = (str) => {
        return str
            .replace(/(_|-)/g, ' ')
            .trim()
            .replace(/\w\S*/g, function (str) {
                return str.charAt(0).toUpperCase() + str.substr(1)
            })
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    }


    const GrantStatementData = props.data.GrantStatementDto.map((innerItem, index) => {
        return {
            Month: innerItem.Month,
            LevyYear: innerItem.LevyYear,
            LevyAmountReceived: innerItem.LevyAmountReceived,
            LevyAmount: innerItem.LevyAmount,
            GrantAmount: innerItem.GrantAmount
        };
    });


    const GrantStatmentContents = GrantStatementData.length !== 0 ?
        (

            <Fragment key={0}>
                <View style={styles.table}>
                    <View key={"0"} style={styles.tableRow} fixed>
                        {Object.keys(GrantStatementData[0]).map((innerItem, index) => (
                            <View style={{ ...styles.tableColHeader, width: (100 / Object.keys(GrantStatementData[0]).length).toString() + '%' }} key={index}>
                                <Text style={styles.tableCellHeader}>{initiateHeaderValue(innerItem)}</Text>
                            </View>
                        ))}
                    </View>
                    {GrantStatementData.map((dataItem, index,array) => (
                        index === array.length -1 ?
                        <View key={index} style={styles.tableRow} wrap={false}>
                        
                            {Object.keys(dataItem).map((innerItem, index) => (
                                <View style={{ ...styles.tableColBoldBlue, width: (100 / Object.keys(GrantStatementData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCellBold}>{dataItem[innerItem]}</Text>
                                </View>
                            ))}

                        </View>
                        :
                        index === array.length -2 ?
                        <View key={index} style={styles.tableRow} wrap={false}>
                        
                            {Object.keys(dataItem).map((innerItem, index) => (
                                <View style={{ ...styles.tableColBold, width: (100 / Object.keys(GrantStatementData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCellBold}>{dataItem[innerItem]}</Text>
                                </View>
                            ))}

                        </View>
                        :
                        <View key={index} style={styles.tableRow} wrap={false}>
                        
                            {Object.keys(dataItem).map((innerItem, index) => (
                                <View style={{ ...styles.tableCol, width: (100 / Object.keys(GrantStatementData[0]).length).toString() + '%' }} key={index}>
                                    <Text style={styles.tableCell}>{dataItem[innerItem]}</Text>
                                </View>
                            ))}

                        </View>
                    ))}
                </View>
            </Fragment>

        ) :
        null;


        const MyDoc = () => (
            <Document>
                <Page orientation="portrait" style={styles.body} size="A4" wrap>
                    <Image style={styles.image} src={mqalogo}></Image>
                    <Text style={styles.hr}></Text>
                    <Text>{"\n"}</Text>
                    <Text style={styles.text}>{new Date().toLocaleDateString('en-ZA') + ''}</Text>
                    <Text style={styles.header}>Mandatory Grant Statement</Text>
                    <Text style={styles.text}>Company:    {props.data.OrganisationName}</Text>
                    <Text style={styles.text}>SDL Number: {props.data.SDLNumber}</Text>
                    <Text style={styles.text}>Thank you for submitting your WSP. The mandatory grant based on the levies received in the period has been approved.</Text>
                    <Text style={styles.text}>The table below shows the levies we have received from your company and the calculated grant amounts.</Text>
                    <Text>{"\n"}</Text>
                    {GrantStatmentContents}
                    <Text>{"\n"}</Text>
                    <Text style={styles.text}>All grants are paid directly into the company's approved bank account.</Text>
                    <Text style={styles.text}>Please contact Ryan Matumba at rmatumba@mqa.org.za should you have any Payment queries.</Text>
                    <Text style={styles.text}>Please contact Siviwe Tywabi at SiviweT@mqa.org.za should you have any Technical queries.</Text>
                    <Text>{"\n"}</Text>
                    <Text style={styles.text}>Your sincerely.</Text>
                    <Text style={styles.text}>SKILLS DEVELOPMENT LEVY OFFICER</Text>
                </Page>
            </Document>
          );

    return (

            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Generate Document for {props.Section}
                    </DialogContentText>
                <PDFDownloadLink document={<MyDoc />} fileName={props.data.OrganisationName + " - " + props.data.SDLNumber + " - Grant Statement - " + props.data.FinancialYear} >
                {({ blob, url, loading, error }) =>
                    loading ? 'Generating Document...' : 'Download Document!'
                }
                </PDFDownloadLink>
                
                </DialogContent>
            </div>

    );
}